<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Minha Wiki</h1>
<!--
<form [formGroup]="formFiltro">
  <div class="row mb-3">
    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Título do Post: </mat-label>
        <input type="text" matInput formControlName="insumoName" />
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>ID do Post: </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="autoFiltro"
          [formControl]="searchUserCtrlFiltro"
          autocomplete="off"
          (click)="resetField(3)"
        />
        <mat-autocomplete #autoFiltro="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading">
            Carregando...
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsersFilter"
              [value]="user.email"
              [value]="user.email"
              (click)="setEmailResponsavel(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <button
        class="btn btn-sm btn-primary"
        (click)="filter(); this.paginator.firstPage()"
      >
        <div class="button-flex">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </div>
      </button>
      <button class="btn btn-secondary btn-sm ml-2" (click)="onReset()">
        <div class="button-flex">
          <mat-icon>backspace</mat-icon>
          Limpar
        </div>
      </button>
    </div>
  </div>
</form> -->

<div class="row mb-5">
  <div class="col-sm-12">
    <p>Lista de Posts:</p>

    <table
      id="FormTable"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="id"
      matSortDirection="desc"
      matSortDisableClear
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="id">
        <th class="id" mat-header-cell *matHeaderCellDef>Id.</th>
        <td class="id" mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th class="name" mat-header-cell *matHeaderCellDef>Título</th>
        <td class="name" mat-cell *matCellDef="let element">
          {{ element.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="publico">
        <th class="publico" mat-header-cell *matHeaderCellDef>Descrição</th>
        <td class="publico" mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="segment">
        <th class="segment" mat-header-cell *matHeaderCellDef>Categoria</th>
        <td class="segment" mat-cell *matCellDef="let element">
          {{ element.wikiCategory.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="competence">
        <th class="competence" mat-header-cell *matHeaderCellDef>
          Subcategoria
        </th>
        <td class="competence" mat-cell *matCellDef="let element">
          {{ element.wikiSubcategory.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="deadline">
        <th class="deadline" mat-header-cell *matHeaderCellDef>
          Tipo de Arquivo
        </th>
        <td class="deadline" mat-cell *matCellDef="let element">
          {{ element.fileType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataDeUpload">
        <th mat-header-cell *matHeaderCellDef>Área Responsável</th>
        <td mat-cell *matCellDef="let element">Projetos e Gestão</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="actions" mat-header-cell *matHeaderCellDef>Ações</th>
        <td class="actions" mat-cell *matCellDef="let element">
          <button
            matSuffix
            data-toggle="modal"
            data-target="#historico"
            matTooltip="Histórico"
            class="btn btn-sm btn-dark mb-1 mt-1 mr-2"
            (click)="getHistoryWikiById(element.id)"
            *ngIf="element.wikiHistories.length > 0"
          >
            <mat-icon>history</mat-icon>
          </button>
          <button
            matTooltip="Editar"
            [routerLink]="['/arquivos/editar-arquivo/', element.id]"
            class="btn btn-sm btn-warning"
          >
            <mat-icon class="material-symbols-outlined"> edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>
    <mat-paginator
      [length]="dataSourceLength"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>

<div
  class="modal fade"
  id="historico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="historico"
  aria-hidden="true"
>

<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title m-0">Histórico</h3>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="row">
        <div class="col-sm-12">
          <section id="cd-timeline" *ngIf="wikis.wikiHistories" class="">
            <div
              class="cd-timeline-block"
              *ngFor="let element of wikis.wikiHistories.reverse()"
            >

              <div class="cd-timeline-img cd-picture"></div>
              <div class="cd-timeline-content bg-white">
                <div>
                  <p class="text-primary mb-0 text-small">
                    <strong>Ação:</strong>
                  </p>
                  <h3 class="text-muted">
                    {{ element.actionEnum | cap }}
                  </h3>
                </div>

                <hr class="mt-2 mb-3"/>

                <div>
                  <p class="text-primary text-small mt-2 mb-0">
                    Usuário da Ação:
                  </p>
                  <h3 class="text-muted mb-0 mt-0">
                    {{ element.actionUserFullName }}
                  </h3>
                  <p class="text-muted mt-0">
                    {{ element.actionUserEmail }}
                  </p>

                  <div>
                    <p class="text-primary text-small mt-2 mb-0">
                      Data da Ação:
                    </p>
                    <h4 class="text-muted mb-0 mt-0">
                      {{ element.creationDate }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
</div>
